import React, { Component } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import get from "lodash/get";
import CardList from "../components/CardList";
import Card from "../components/Card";
import Cardboard from "../components/Cardboard";
import PaginationBlock from "../components/PaginationBlockAtom";
import SEO from "../components/seo";

class ArticlesIndex extends Component {
	render() {
		const posts = get(this, "props.data.recraft.entries");
		const { currentPage, numPages } = this.props.pageContext;
		const isFirst = currentPage === 1;
		const isLast = currentPage === numPages;
		const prevPage =
			currentPage - 1 === 1
				? "blogging/"
				: "blogging/" + (currentPage - 1).toString();
		const nextPage = "blogging/" + (currentPage + 1).toString();
		return (
			<Layout>
				<SEO title="News" keywords={[`dumbo`, `places`, `dumbo businesses`]} />
				<div className="container">
					<Cardboard>
						<CardList>
							{posts.map((entry, i) => (
								<Card
									key={i}
									mainLink={`/${entry.uri}`}
									title={entry.title}
									imageObject={
										!entry.featuredImage || !entry.featuredImage.length
											? null
											: entry.featuredImage[0]
									}
									catName={
										entry.newsCategory.length
											? entry.newsCategory[0].title
											: null
									}
									noBorder={true}
									avatarStyle={false}
								/>
							))}
						</CardList>
					</Cardboard>
				</div>

				<PaginationBlock
					nextPage={nextPage}
					prevPage={prevPage}
					numPages={numPages}
					currentPage={currentPage}
					isFirst={isFirst}
					isLast={isLast}
					linkWord={"blogging"}
				/>
			</Layout>
		);
	}
}

export default ArticlesIndex;

export const newsListQuery = graphql`
	query newsListQuery($offset: Int!, $limit: Int!) {
		recraft {
			entries(
				section: [news]
				limit: $limit
				offset: $offset
				orderBy: "postDate desc"
			) {
				... on Recraft_News {
					title
					uri
					slug
					featuredImage {
						url
						focalPoint {
							x
							y
						}
					}
					body {
						content
					}
					newsCategory {
						title
						friendlyName
					}
					postDate
				}
			}
		}
	}
`;

// const posts = get(this, "props.data.craft.entries");
// <h4>{totalCount}</h4>

// <div className="w-full flex px-4 mb-6">
// <h2>Title</h2>
// <h4>{totalCount}</h4>
// </div>
